<template>
  <div>
    <b-row class="mt-0 mt-lg-3">
      <b-col xl="3">
        <card :card="mod" :link="false" :seeMore="true" />
      </b-col>
      <b-col xl="9">
        <b-row>
          <b-col xl="12">
            <div>
              <b-card
                class="card-custom gutter-b"
                body-class="p-0 d-flex flex-column"
                header-class="border-0 pt-5"
              >
                <template #header>
                  <b-card-title class="font-weight-bolder">
                    <div class="card-label">
                      {{ $t("profile.burnout.help.title") }}
                    </div>
                  </b-card-title>
                </template>
                <div class="card-body text-left">
                  <p
                    v-for="(value, key) in $t(
                      'profile.burnout.help.description'
                    )"
                    :key="key"
                    v-html="value"
                  />
                </div>
              </b-card>
              <b-card
                class="card-custom card-stretch gutter-b mb-6"
                no-body
                v-if="tabs.length > 0"
              >
                <div class="card-body d-flex flex-column text-left">
                  <span v-for="action in tabs" :key="action.dimensionId">
                    <span v-html="action.executeAction.description" />
                    <div
                      class="assets mt-8"
                      v-if="
                        action.executeAction.assets &&
                          action.executeAction.assets.length > 0
                      "
                    >
                      <b-button
                        tag="a"
                        class="mr-4 mb-4"
                        variant="light"
                        v-for="asset in action.executeAction.assets"
                        :key="asset.id"
                        :class="'asset-' + asset.type"
                        @click="downloadFile(asset)"
                      >
                        <span v-if="asset.type === 'youtube_iframe'">
                          <i class="socicon-youtube text-danger"></i>
                          {{ asset.link }}
                        </span>
                        <span v-else-if="asset.type === 'ted_iframe'">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 324 119" v-bind:svg-inline="''" v-bind:style="'max-width: 1.1rem; height: auto; margin-right: 3px;'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#E62B1E" d="M32.678 30.831H0V.85h101.56v29.981H68.875V118.3H32.678V30.831zM107.18.85h98.75v29.981h-62.55v14.462h62.55v28.212h-62.55v14.814h62.55V118.3h-98.75V.85zm104.72 0h59.39C310.3.85 324 29.773 324 59.401c0 35.975-18.98 58.899-59.74 58.899H211.9V.85zm36.2 87.469h14.05c22.49 0 25.66-18.337 25.66-29.27 0-7.41-2.46-27.865-28.47-27.865h-11.6l.36 57.135z"/></svg>
                          {{ asset.link }}
                        </span>
                        <span v-else-if="asset.type === 'vimeo_iframe'">
                          <i class="socicon-vimeo text-danger"></i>
                          {{ asset.link }}
                        </span>
                        <span v-else>
                          <i :class="'fas fa-file-' + asset.type"></i>
                          {{ asset.link }}
                        </span>
                      </b-button>
                    </div>
                  </span>
                </div>
              </b-card>
              <empty-card type="profile.burnout" v-else />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            xl="4"
            class="mb-8 mt-8"
            v-for="interaction in visibleInteractions"
            :key="interaction.surveyEntityId"
          >
            <card :card="interaction" :date="interaction.date" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import { getUserActions } from "@/api/user/traits.api";

import Card from "@/components/profile/Card.component";
import EmptyCard from "@/components/EmptyCard.component.vue";

export default {
  name: "Burnout",
  components: {
    Card,
    EmptyCard
  },
  methods: {
    ...mapActions("Profile", ["loadProfile"]),
    ...mapActions("Interactions", ["loadInteractions"]),
    mount() {
      if (this.isConstantsActive && !this.isProfileLoaded) this.loadProfile();
      if (this.isConstantsActive && !this.isInteractionsLoaded)
        this.loadInteractions();
      if (this.isConstantsActive) this.loadRecommendations();
    },
    loadRecommendations() {
      var params = {
        groupDimensions: [this.getConstant("DIMENSION_GROUP_BURNOUT")],
        actionTypes: [this.getConstant("ACTIONS_TYPE_RECOMMENDATION")],
        entityType: this.getConstant("ENTITY_TYPE_USER"),
        maxValue: "NO"
      };
      getUserActions(params).then(res => {
        this.tabs = res.data.records;
      });
    }
  },
  data() {
    return {
      object: {},
      tabs: []
    };
  },
  computed: {
    ...mapGetters("Profile", ["isProfileLoaded", "modules"]),
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    ...mapGetters("Interactions", [
      "isInteractionsLoaded",
      "interactions",
      "getBySurveyType"
    ]),
    visibleInteractions() {
      return this.getBySurveyType([this.getConstant("SURVEY_KYMATIO_BURNOUT")]);
    },
    mod() {
      return _.find(this.modules, e => {
        return e.id === "burnout";
      });
    }
  },
  mounted() {
    this.mount();
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) {
        this.mount();
      }
    }
  }
};
</script>
